import React from "react";
import { motion } from "framer-motion";
import Mover from "../Animations/Cursor";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import { fadeIn, slideIn } from "../utils/motion";
import ColorTitles from "../utils/colorTitles";
import { Link } from "react-router-dom";

const Hero = () => {
  const [text] = useTypewriter({
    words: [
      "Robotics",
      "Internet of Things",
      "Scratch",
      "Javascript",
      "Chess",
      "Python",
    ],
    loop: true,
    delaySpeed: 1000,
  });

  return (
    <div className="bg-[#03132e] h-screen">
      <Mover />
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 md:py-28 ">
          <motion.div
            variants={slideIn("left", "tween", 0.5, 2)}
            initial="hidden"
            whileInView={"show"}
            className="hidden sm:mb-8 sm:flex sm:justify-center"
          >
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-400 ring-1 ring-gray-500/20 hover:ring-gray-900/20">
              Fun Learning for kids.{" "}
              <a href="#blog" className="font-semibold text-indigo-400">
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("up", "tween", 0.9, 2)}
            initial="hidden"
            whileInView={"show"}
            className="text-center"
          >
            <h1 className="text-4xl font-bold tracking-tight text-[#942a4f] sm:text-7xl">
              <ColorTitles text={"Funlings Entertainment"} />
              {/* Funlings Entertainment */}
            </h1>
            {/* <div>
              <Carousel />
            </div> */}
            <p className="text-2xl my-4 text-yellow-400 sm:text-3xl font-bold">
              <span>{text}</span>
              <Cursor cursorColor="#ffff" />
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-400">
              Empower your kids with a premium online learning experience that
              encourages imagination and collaborative creation with their
              peers.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={"/signup"}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Enroll Now
              </Link>
              <a
                href="#blog"
                className="text-sm font-semibold leading-6 text-gray-300"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </motion.div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
