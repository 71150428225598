import React from "react";
import { motion } from "framer-motion";
import { zoomIn } from "../utils/motion";
import ColorTitles from "../utils/colorTitles";

export const staff = [
  {
    id: 1,
    name: "Doris Ratego",
    role: "Co-Founder / CEO",
    imageUrl: "/images/doris.jpg",
  },
  {
    id: 2,
    name: "Sammy Mochama",
    role: "Co-Founder / GM",
    imageUrl: "/images/mochama.jpg",
  },
];

const Team2 = () => {
  return (
    <article className="bg-gray-100" id="Team">
      <section className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <section className="space-y-12">
          <section className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h3 className="mt-2 text-3xl inline-block font-extrabold text-black sm:text-4xl lg:text-5xl">
              Meet Our <ColorTitles text={"Team"} />
            </h3>
            <span className="block w-32 h-1 mx-auto bg-primary mt-4"></span>
            <p className="mt-3 max-w-4xl mx-auto text-lg font-light text-gray-800 sm:mt-5 sm:text-xl">
              The Team Fueling the Engine Called Funlings, our team is dedicated
              to building an entire ecosystem of efficient and enjoyable
              learning. It consists of visionary entrepreneurs, hardworking
              developers, enthusiastic educators, ingenious innovators, adept
              content creators, and skilled executives. When designing our
              products, we prioritize engagement and user experience to create a
              fun and effective learning process.
            </p>
          </section>
          <ul className="mx-auto space-y-0 grid grid-cols-2 gap-16  lg:max-w-5xl">
            {staff.map((person, index) => (
              <li key={person.id}>
                <section className="space-y-6 ">
                  <motion.img
                    variants={zoomIn(index * 0.5, 1)}
                    initial="hidden"
                    whileInView={"show"}
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56 object-cover "
                    src={person.imageUrl}
                    alt="team member"
                  />
                  <section className="space-y-2">
                    <section className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="text-primary">{person.name}</h3>
                      <p className="text-purple-600">{person.role}</p>
                    </section>
                    {/* <Link
                      to={`/profile?id=${person.id}`}
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-600 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  dark:hover:bg-purple-700 "
                    >
                      <p>View Profile</p>
                    </Link> */}
                  </section>
                </section>
              </li>
            ))}
          </ul>
        </section>
      </section>
    </article>
  );
};

export default Team2;
