import React, { useCallback, useRef } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { quoteChoices } from "./Testimonials";
import ColorTitles from "../utils/colorTitles";

const Testimonial = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <section className=" bg-[#972a502e] pt-8 pb-20  lg:pb-[120px]">
        <div className="flex justify-center mb-4">
          <h3 className="mt-4 text-3xl inline-block py-4 font-extrabold text-center text-black sm:text-4xl lg:text-5xl">
            <ColorTitles text={"Testimonials"} />
          </h3>
        </div>
        <div className="container mx-auto">
          <Swiper slidesPerView={1} ref={sliderRef}>
            {quoteChoices.map((choice) => (
              <SwiperSlide>
                <SingleTestimonial details={choice.value} name={choice.name} />
              </SwiperSlide>
            ))}
            {/* <SwiperSlide>
              <SingleTestimonial
                image="https://cdn.tailgrids.com/2.0/image/marketing/images/testimonials/testimonial-01/image-01.jpg"
                reviewImg="https://cdn.tailgrids.com/2.0/image/marketing/images/testimonials/testimonial-01/lineicon.svg"
                reviewAlt="lineicon"
                details="Velit est sit voluptas eum sapiente omnis! Porro, impedit minus quam reprehenderit tempore sint quaerat id! Mollitia perspiciatis est asperiores commodi labore!"
                name="Larry Diamond"
                position="Chief Executive Officer."
              />
            </SwiperSlide> */}
            <div className="absolute z-50 left-0 right-0 bottom-0 flex items-center justify-center lg:pl-[120px] 2xl:pl-0">
              <div className="prev-arrow cursor-pointer" onClick={handlePrev}>
                <button className="text-primary hover:bg-indigo-600 shadow-input mx-1 flex h-12 w-12 items-center justify-center rounded-full bg-white transition-all hover:text-white">
                  <svg
                    width="15"
                    height="13"
                    viewBox="0 0 15 13"
                    className="fill-current"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.24264 11.8033L0.46967 7.03037C0.176777 6.73748 0.176777 6.2626 0.46967 5.96971L5.24264 1.19674C5.53553 0.903845 6.01041 0.903845 6.3033 1.19674C6.59619 1.48963 6.59619 1.96451 6.3033 2.2574L2.81066 5.75004H14C14.4142 5.75004 14.75 6.08583 14.75 6.50004C14.75 6.91425 14.4142 7.25004 14 7.25004H2.81066L6.3033 10.7427C6.59619 11.0356 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033Z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="next-arrow cursor-pointer" onClick={handleNext}>
                <button className="text-primary hover:bg-indigo-600 shadow-input mx-1 flex h-12 w-12 items-center justify-center rounded-full bg-white transition-all hover:text-white">
                  <svg
                    width="15"
                    height="13"
                    viewBox="0 0 15 13"
                    className="fill-current"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.75736 11.8033L14.5303 7.03037C14.8232 6.73748 14.8232 6.2626 14.5303 5.96971L9.75736 1.19674C9.46447 0.903845 8.98959 0.903845 8.6967 1.19674C8.40381 1.48963 8.40381 1.96451 8.6967 2.2574L12.1893 5.75004H1C0.585786 5.75004 0.25 6.08583 0.25 6.50004C0.25 6.91425 0.585786 7.25004 1 7.25004H12.1893L8.6967 10.7427C8.40381 11.0356 8.40381 11.5104 8.6967 11.8033C8.98959 12.0962 9.46447 12.0962 9.75736 11.8033Z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

const SingleTestimonial = ({ details, name }) => {
  return (
    <div className="relative flex justify-center">
      <div className="relative w-full pb-16 md:w-11/12 lg:w-10/12 xl:w-8/12 xl:pb-0">
        <div className="w-full items-center md:flex">
          <div className="relative hidden md:block mb-12 w-full items-center md:mr-12 md:mb-0 md:max-w-[120px] lg:mr-14 lg:max-w-[150px] 2xl:mr-16">
            <img
              src={"/images/logo.png"}
              alt="logo"
              className="h-28 w-48 rounded-md xl:h-36 xl:w-64"
            />
            {/* <span className="absolute -top-6 -left-6 z-[-1] hidden sm:block">
               <DotShape />
            </span> */}
            {/* <span className="absolute -bottom-6 -right-6 z-[-1]">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 32C3 15.9837 15.9837 3 32 3C48.0163 2.99999 61 15.9837 61 32C61 48.0163 48.0163 61 32 61C15.9837 61 3 48.0163 3 32Z"
                  stroke="#13C296"
                  strokeWidth="6"
                />
              </svg>
            </span> */}
          </div>
          <div className="w-full px-4">
            <div>
              {/* <div className="mb-7">
                <img src={reviewImg} alt={reviewAlt} />
              </div> */}
              <p className="text-body-color mb-4 md:mb-11  text-base font-medium italic sm:text-lg">
                {details}
              </p>
              <h4 className="text-dark text-xl font-semibold">{name}</h4>
              {/* <p className="text-body-color text-base">{position}</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const DotShape = () => {
//   return (
//     <>
//       <svg
//         width="77"
//         height="77"
//         viewBox="0 0 77 77"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <circle
//           cx="1.66343"
//           cy="74.5221"
//           r="1.66343"
//           transform="rotate(-90 1.66343 74.5221)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="1.66343"
//           cy="30.94"
//           r="1.66343"
//           transform="rotate(-90 1.66343 30.94)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="16.3016"
//           cy="74.5221"
//           r="1.66343"
//           transform="rotate(-90 16.3016 74.5221)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="16.3016"
//           cy="30.94"
//           r="1.66343"
//           transform="rotate(-90 16.3016 30.94)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="30.9398"
//           cy="74.5221"
//           r="1.66343"
//           transform="rotate(-90 30.9398 74.5221)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="30.9398"
//           cy="30.94"
//           r="1.66343"
//           transform="rotate(-90 30.9398 30.94)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="45.578"
//           cy="74.5221"
//           r="1.66343"
//           transform="rotate(-90 45.578 74.5221)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="45.578"
//           cy="30.94"
//           r="1.66343"
//           transform="rotate(-90 45.578 30.94)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="60.2162"
//           cy="74.5216"
//           r="1.66343"
//           transform="rotate(-90 60.2162 74.5216)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="74.6634"
//           cy="74.5216"
//           r="1.66343"
//           transform="rotate(-90 74.6634 74.5216)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="60.2162"
//           cy="30.9398"
//           r="1.66343"
//           transform="rotate(-90 60.2162 30.9398)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="74.6634"
//           cy="30.9398"
//           r="1.66343"
//           transform="rotate(-90 74.6634 30.9398)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="1.66343"
//           cy="59.8839"
//           r="1.66343"
//           transform="rotate(-90 1.66343 59.8839)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="1.66343"
//           cy="16.3017"
//           r="1.66343"
//           transform="rotate(-90 1.66343 16.3017)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="16.3016"
//           cy="59.8839"
//           r="1.66343"
//           transform="rotate(-90 16.3016 59.8839)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="16.3016"
//           cy="16.3017"
//           r="1.66343"
//           transform="rotate(-90 16.3016 16.3017)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="30.9398"
//           cy="59.8839"
//           r="1.66343"
//           transform="rotate(-90 30.9398 59.8839)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="30.9398"
//           cy="16.3017"
//           r="1.66343"
//           transform="rotate(-90 30.9398 16.3017)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="45.578"
//           cy="59.8839"
//           r="1.66343"
//           transform="rotate(-90 45.578 59.8839)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="45.578"
//           cy="16.3017"
//           r="1.66343"
//           transform="rotate(-90 45.578 16.3017)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="60.2162"
//           cy="59.8839"
//           r="1.66343"
//           transform="rotate(-90 60.2162 59.8839)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="74.6634"
//           cy="59.8839"
//           r="1.66343"
//           transform="rotate(-90 74.6634 59.8839)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="60.2162"
//           cy="16.3017"
//           r="1.66343"
//           transform="rotate(-90 60.2162 16.3017)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="74.6634"
//           cy="16.3017"
//           r="1.66343"
//           transform="rotate(-90 74.6634 16.3017)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="1.66343"
//           cy="45.2455"
//           r="1.66343"
//           transform="rotate(-90 1.66343 45.2455)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="1.66343"
//           cy="1.66342"
//           r="1.66343"
//           transform="rotate(-90 1.66343 1.66342)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="16.3016"
//           cy="45.2455"
//           r="1.66343"
//           transform="rotate(-90 16.3016 45.2455)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="16.3016"
//           cy="1.66342"
//           r="1.66343"
//           transform="rotate(-90 16.3016 1.66342)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="30.9398"
//           cy="45.2455"
//           r="1.66343"
//           transform="rotate(-90 30.9398 45.2455)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="30.9398"
//           cy="1.66342"
//           r="1.66343"
//           transform="rotate(-90 30.9398 1.66342)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="45.578"
//           cy="45.2455"
//           r="1.66343"
//           transform="rotate(-90 45.578 45.2455)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="45.578"
//           cy="1.66344"
//           r="1.66343"
//           transform="rotate(-90 45.578 1.66344)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="60.2162"
//           cy="45.2458"
//           r="1.66343"
//           transform="rotate(-90 60.2162 45.2458)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="74.6634"
//           cy="45.2458"
//           r="1.66343"
//           transform="rotate(-90 74.6634 45.2458)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="60.2162"
//           cy="1.66371"
//           r="1.66343"
//           transform="rotate(-90 60.2162 1.66371)"
//           fill="#3056D3"
//         />
//         <circle
//           cx="74.6634"
//           cy="1.66371"
//           r="1.66343"
//           transform="rotate(-90 74.6634 1.66371)"
//           fill="#3056D3"
//         />
//       </svg>
//     </>
//   );
// };
