import React from "react";

const Partners = () => {
  return (
    <section
      className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8"
      id="partners"
    >
      <h2 className="text-3xl font-extrabold text-center tracking-tight mb-8 text-gray-900 lg:text-5xl">
        Our Partners
      </h2>
      <section className="grid md:grid-cols-3 grid-cols-2 gap-8 items-baseline">
        <section className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://www.critical-links.com/wp-content/uploads/2018/05/s2.png"
            alt="Critical Links"
          />
        </section>
        <section className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-12" src="/images/magicbit.png" alt="magicbit" />
        </section>
        {/* <section className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://fastly.codingal.com/images/logos/logos-main/logo-with-text.svg"
            alt="Codingal"
          />
        </section> */}

        <section className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img className="h-28" src="/images/koding2.png" alt="koding2" />
        </section>
      </section>
    </section>
  );
};

export default Partners;
