import React from "react";
import { useState } from "react";
import ColorTitles from "../utils/colorTitles";

const colorPool = [
  { text: "text-red-300", bg: "bg-indigo-300" },
  { text: "text-blue-300", bg: "bg-orange-300" },
  { text: "text-green-300", bg: "bg-pink-300" },
  { text: "text-purple-300", bg: "bg-gray-300" },
  { text: "text-yellow-300", bg: "bg-blue-300" },
  { text: "text-white", bg: "bg-indigo-300" },
];

const faqs = [
  {
    id: 1,
    title: "What is Funlings?",
    description:
      "Funlings is an Edtech organization that provides online classes for kids.",
  },
  {
    id: 2,
    title: "What are Funlings Online live coding Classes?",
    description:
      "Our online classes are curated for kids from the age of 6-17. Our trainers give live classes which happen face to face sessions.",
  },
  {
    id: 3,
    title:
      "What is the right age for kids to start coding and other online classes?",
    description:
      "Kids can start coding right from the age of 6. Our trainers teach kids coding online in the most personalized way to suit the needs of the kids from different age groups.",
  },
  {
    id: 4,
    title: "What other skills does coding teach kids?",
    description:
      "Starting to code early imbibes a strong foundation of cognitive skills, logical and mathematical thinking skills. These skills are beneficial for any kid, irrespective of their educational background.",
  },
  {
    id: 5,
    title: "Are the classes always Live?",
    description: "Yes, we offer personalized and live online classes for kids.",
  },
  {
    id: 6,
    title: "What are the minimum requirements to join Funlings Online classes?",
    description:
      "A laptop/desktop computer, a webcam and a stable internet connection are all you need to get started with our classes.",
  },
];

const FAQS = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="relative bg-green-100 overflow-hidden">
      <svg
        className="absolute top-2 left-3/4 -ml-3"
        width={400}
        height={400}
        fill="none"
        viewBox="0 0 404 392"
      >
        <defs>
          <pattern
            id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-blue-600"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={392}
          fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
        />
      </svg>
      <div className="mx-auto max-w-7xl md:py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16  lg:px-24 ">
          {/* <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg> */}
          <section
            aria-hidden="true"
            className="absolute -z-10 inset-0 -mt-72 sm:-mt-32 md:mt-0"
          >
            <svg
              className="absolute inset-0 h-full w-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
            >
              <path
                className="text-primary-dark text-opacity-40"
                fill="#7775D62e"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
              />
              <path
                className="text-primary-dark text-opacity-40"
                fill="#E935C12e"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
              />
            </svg>
          </section>

          <h2 className="mb-4 text-5xl text-center tracking-wide font-extrabold text-gray-900 ">
            <ColorTitles text={"FAQs"} />
          </h2>

          <p className="font-light text-center text-gray-500 mb-8 sm:text-xl ">
            Got questions? We've got answers!
          </p>

          <div className="mx-auto   text-center lg:mx-0 lg:flex-auto pb-12 lg:text-left">
            {faqs.map((item, index) => (
              <div id="accordion-open" data-accordion="open">
                <h2 id="accordion-open-heading-1">
                  <button
                    type="button"
                    className={`flex items-center justify-between w-full p-5 font-medium text-left bg-gray-300/25 border border-b-0 border-gray-200  focus:ring-2 focus:ring-gray-600 ${colorPool[index].text} `}
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="flex items-center">
                      <svg
                        className="w-5 h-5 mr-2 shrink-0"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>{" "}
                      {item.title}
                    </span>
                    <svg
                      data-accordion-icon
                      className={`w-6 h-6 ${
                        activeIndex === index ? "rotate-180" : ""
                      } shrink-0`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  className={`mx-4 ${colorPool[index]} ${
                    activeIndex === index ? "block" : "hidden"
                  }`}
                >
                  <div
                    className={`p-5 border border-b-0 border-gray-200 ${colorPool[index].bg}`}
                  >
                    <p className="mb-2 text-gray-700 ">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQS;
