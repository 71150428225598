import React from "react";
import ColorTitles from "../utils/colorTitles";
import { Link } from "react-router-dom";
import { fadeIn } from "../utils/motion";
import { motion } from "framer-motion";

export const posts = [
  {
    id: 5,
    title: "An introduction to robotics for kids",
    author: "Funlings Entertainment",
    publicationDate: "November 29, 2022",
    description:
      "Discover how robotics education benefits kids and prepares them for the future.",
    imageUrl: "/images/IOT.jpg",
    content: [
      "Empower your kids with future academic and career skills that will be vitally important in 2030. With early robotics and coding education, kids learn to be active problem solvers and develop real tech-enabled solutions to problems we face today.",
      "Robotics is easily adopted by young minds today who have grown up with Transformers, Wall-E, & Iron Man.",
      "A robot is a goal-oriented machine that generally can sense, plan and act all on its own without a human controlling them. The goal of the robot is its “purpose”, which can be moving around without hitting things, moving other things between places, cleaning, cooking, exploring etc. When talking about a robot, sense, plan, and act mean:",
      "Sense: The robot can “see” its environment, for example a self driving car can see the road and cars in front of it. It needs to understand its environment to perform the correct actions in its plan.",
      "Plan: The robot can decide what to do given what it has seen to achieve the goal it has been instructed (programmed) to do, such as a self driving car turning on the road based on its location.",
      "Act: The robot can move (actuate) on its own, whether propellers (drones), legs (humanoids), wheels (mobile robots), arms (robotic manipulators) etc.. A self driving car can regulate throttle and press the gas pedal or break automatically based on its plan.",
      "In order to sense a robot has sensors, and in order to plan a robot generally has a computer somewhere, and finally to act a robot will have a movable part (wheels, arms, etc.). Here is an example robot where all of these parts are visible:",
      "Robots are also known to be consistent, accurate, and reliable. What do each of these mean for a robot?",
      "Consistent: The robot is able to perform the same task repeatedly the same way (the action is deterministic.)",
      "Accurate: The robot can perform its actions with a high degree of accuracy, for example if it was an archery robot, it would always hit the center of the bullseye.",
      "Reliable: The robot should not fail/require maintenance often. For example, a robot that uses an arm to move things should not frequently need new parts in its arm, it should be able to handle the wear from repeated motions without losing accuracy over time.",
      "Robots in the world today",
      "Robots are great for things people can’t do (in harsh environments such as space, the deep sea), won’t do (boring, dull), or shouldn’t do (dangerous, unhealthy). Robots come in all shapes and sizes, designed to be good at the job they perform. ",
      "Some robots in existence today:",
      "Softbank robotics have created robots called Pepper and Nao. These robots are used in hospitality to greet and help check in guests, and in educational settings to teach kids robotics and basic educational principles.",
      "Boston Dynamics have created a variety of robots, known most famously for their Spot robot dog, that can be found at Disneyland, or walking around and take pictures of dangerous areas such as chemical plants.",
      "Brain Corp has created a robotic brain to automatically drive scrubbing robots around public stores that keep floors clean.",
      "Toyota has worked on a robot to help out in the home, from folding laundry to organizing shelves and moving things throughout the house.",
      "Sony has created its own robot dog aibo, and example of human robot interaction research. Robot companions are commonly used to provide low maintenance pets that can still react to interactions from their owners!",
      "The above is just a small collection of robots in the world today, and there are many more with many more roles, such as:Agricultural robots for farming, Factory robots for car production, Warehouse robots for inventory management and movement, Underwater robots for exploration, Many more!",
    ],
    tags: [
      "Robotics",
      "Kids",
      "STEM Education",
      "Technology",
      "Problem Solving",
    ],
  },
  {
    id: 6,
    title: "The Future of Education",
    author: "Funlings Entertainment",
    publicationDate: "November 11, 2021",
    description:
      "Learn why teaching IoT to kids is essential in today's interconnected world.",
    imageUrl: "/images/futureed.png",
    content: [
      "Education must adapt to the Fourth Industrial Revolution.",
      "Technology is transforming how we live, work, play and think. And it's happening more quickly and on a larger scale, than at any point in human history....",
      "Education needs to equip today's young people with the skills to thrive in tomorrow's world.",
      "Computers can not only be programmed to fulfil many human tasks, but learn how to do things for themselves, applying their processing power to massive datasets. Within just a few years, developments in technologies such as artificial intelligence, robotics, nanotechnology and 3D printing will transform most occupations.",
      "According to the World Economic Forum’s Future of Jobs Survey, “a wide range of occupations will require a higher degree of cognitive abilities — such as creativity, logical reasoning and problem sensitivity — as part of their core skill set.",
      "Half of today’s work activities could be automated by 2055.",
      "Less than 5% of occupations can be automated completely, but 60% could see 30% of their constituent activities automated.",
      "This is a very different world to the one our schools and universities were designed to serve. Formal education came into being around the time of the first Industrial Revolution, and early schools were less about improving children’s minds than producing a punctual, obedient workforce for the new factories. As a conveyor belt for sorting, training and disciplining future workers, they were a kind of factory themselves.",
      "“If you look at early images of the factory and early images of the school room, there’s not a lot of difference,” says sociologist and education specialist John Holm at SocioDesign in Australia. “The children are in rows, they’re facing front and they’re looking unhappy.”",
      "In many respects, things have changed little. In today’s classrooms and lecture theatres, students are still expected to sit in rows, listening to the teacher.",
      "This “industrial classroom” is no longer fit for purpose, argues educationalist Erica McWilliam: “Schooling as a preparation for the future continues to anticipate a social order that is on the wane.”",
      "We need to reshape it for the 21st century.",
      "How do you teach a digital native when they can just Google it?",
      "Now that information is widely available, we see the student taking an increasing amount of ownership and guiding their own learning, with the teacher providing mentorship and context along the way.",
      "With a world of readily searchable knowledge at our fingertips, we don’t need to memorize facts any more. In fact, many things we traditionally learned at school might start to feel a little pointless in the digital age: handwriting, the rules of spelling and grammar, foreign languages …",
      "But we will need new skills to help us manage the formidable tools at our disposal. We need to know how to interpret search results, critically assess the quality and veracity of information and make ethical judgements about how to use it, and we’ll need to think creatively to come up with solutions to increasingly complex global problems.",
      "In the future, work will be structured around projects, not processes. That’s an important trend in education too.",
      "“Active” or “problem-based” learning seeks to engage students’ natural curiosity, rather than simply presenting them with information. “That’s the big shift in the way we’re teaching: we’re starting to mix things up,” says John Holm at SocioDesign. “Instead of just saying ‘here’s stuff to remember’, it says ‘here’s a problem to solve’ and the students get involved in that problem.”",
      "That means blurring traditional curriculum boundaries. Instead of splitting learning into different subjects, topics are taught in a more holistic, real-world way — so a lesson on the Vikings might include learning about history or geography, writing stories or working in a group to design and build a boat.",
      "This is “phenomenon-based learning”. It emphasizes skills such as communication, creativity and critical thinking, and better prepares students to apply their knowledge in the 21st-century workplace.",
      "It’s big in Finland. Long recognized as having one of the world’s most successful education models, Finland is adopting phenomenon-based learning for an increasing proportion of teaching time.",
      "In this new world, the teacher plays a very different role. Today’s students are the first generation to have grown up with the internet, and the first to be educated by it. For both students and teachers, this new learning journey is uncharted territory. So how can the teacher lead?",
      "Educationalist Erica McWilliam has characterized a shift from the teacher as the “sage on the stage” to the “guide on the side” to the “meddler in the middle”. The meddler in the middle learns alongside students, challenging them to expand their horizons.",
      "Next-generation learning spaces will be ‘flipped’.",
      "The teacher-as-meddler doesn’t need to stand at the front of the class to impart their wisdom, and students don’t come to class just to listen. So they don’t need to sit in rows facing the front. And why do we even need a “front”?",
      "In the education system of the future, homework will happen before the lesson. “Teachers can record structured content for you to absorb at your own pace,” says John Holm at SocioDesign. “Then when you come into the classroom, they can help you solve the problem.”",
      "This is the “flipped” classroom. It’s bigger: Holm says that traditional desks in rows allowed around 1m2 per student. In an active learning space, that rises to 3m2. And it’s totally wired: multiple monitors allow students to review course materials and look things up on the internet as an intrinsic part of the classroom experience. This combination of traditional teaching and online media is called “blended learning”.",
      "Collaboration will be a core skill.",
      "As routine tasks are taken over by computers, workers will be valued for the creativity and intuition that only the human mind can offer (for now). They will be prized for their ability to innovate, communicate and collaborate in global teams.",
      "Technology is at the heart of the learning space of the future.",
      "“When technology arrived in the classroom, all it was doing was enhancing the blackboard or the whiteboard or the projection surface.",
      "In this new kind of learning space, students use multiple media sources simultaneously, sitting alongside people from different disciplines who are solving very different problems, alone or in groups. Learning shouldn’t be a one-way flow of information.",
      "The technology needs to support real-time student-to-student, teacher-to-student and student-to-teacher interaction. Today we can use the flexibility of data networks and software to create dynamic learning environments, without the huge cost and rigidity of traditional AV infrastructure.",
      "The next frontier is a totally immersive teaching experience. Tools for 3D models and visualizations already exist — now we just have to make them work at scale in the classroom or lecture theatre: Being able to take 3D models and interact with them will become the norm.",
    ],
    tags: ["IoT", "Kids", "Technology", "STEM Education", "Connected Devices"],
  },
  {
    id: 3,
    title:
      "Project Based-Learning explained using The Golden Circle (Why What and How)",
    author: "Funlings Entertainment",
    publicationDate: "October 11, 2022",
    description: "An introduction to JavaScript programming for children.",
    imageUrl: "/images/projectb.png",
    content: [
      "In this article, I want to elaborate on the fundamental concepts of Project Based Learning.",
      "The Golden Circle by Simon Sinek” is very helpful when explaining something to someone. It really can create enough momentum for other people to comprehend the concepts easily.",
      "So I thought of using this methodology with a little tweak to explain Project Based Learning. Here I am going to start with ‘Why’ (The Purpose) and then it will guide us to ‘What’ (The Expected Outcome) and finally to ‘How’ (The Process).",
      "Why Project-Based Learning (PBL)?",
      "The simplest and straightforward answer to this would be that we live in a project-based world. Day by day the world is getting more complex yet simple at the same time. Exponential technologies are driving changes in every sector.",
      "The rapidly changing landscape of the education domain has created a new set of roles and responsibilities for teachers and students. Project Based Learning encapsulates all the skills and knowledge that students should need to learn for them to become career or professional-ready in the future.",
      "What is Project-Based Learning (PBL)?",
      "PBL is an instructional methodology that is designed in a way to provide students with a set of skills and knowledge by letting them engage in projects that they may face in the real world in order to make them career-ready.",
      "Traditional Learning vs PBL",
      "In traditional learning environments, we usually provide explicit instructions and transfer specific knowledge on a topic that students are expected to be educated on.",
      "In PBL it changes this order plus the teacher's role also. Teachers will act more as a facilitator in this scenario. It starts by presenting the problem to students and then students need to gather the necessary skills or information in order to solve the problem and finally present the solution. If the students do not participate in the process then there will be no learning involved.",
      "Since we are now clear on Why we need PBL and What PBL is? the next important question would be How educators are facilitating or preparing students for their future with longevity and at scale?",
      "How can we use Project Based Learning (PBL)?",
      "Now let’s see how we can take a project and convert that into a Project Based Learning experience. For this, the most recommended way is to use the 8 Essential Elements of PBL, as designated by the Buck Institute for Education. This will allow teachers to design a learning experience that promotes students to engage in project-based learning activities.",
      "Significant Content: The project is focused on important knowledge, concepts, and skills derived from curriculum standards and key concepts.",
      "Need to Know: This is an important driving force in the PBL as it provides students with a purpose to learn, understand concepts, and apply skills when the questions they have generated are meaningful to them.",
      "Driving Question: Project work is guided by an intriguing, open-ended question. A good driving question will capture the project’s focus, be easy to understand and provide a sense of challenge.",
      "Voice and Choices: Students make some decisions about the project, including how they work and what they create.",
      "21st Century Skills: These are very valuable sets of skills for today’s world which include student's ability to focus on critical thinking, collaboration, creativity and communication, and other skills needed for success in today’s world.",
      "In-depth Inquiry: Students engage in a rigorous extended process of asking questions, gathering information, and developing answers.",
      "Reflection and Revision: This is an important step in PBL. Ability to give self, peer, and teacher feedback create high-quality products, think about what students have learned, how they are learning, and what they may do differently in the future.",
      "Public Presentation: Students create products for or present work to people beyond their classroom.",
      "Reflection",
      "Educators should start making Students’ future-ready from the school environment itself. They should not be educated in the same way that educators used to learn. Students should not memorize all the facts for a test when there are more ways of learning new things. There is no ‘one-size-fits-all’ concept when it comes to education.",
      "The current cohort of students comes from Generation Z and Generation Alpha and these students are very much goal-oriented, tech-savvy, extremely intelligent, and independent. With so much readily available information, now there are multiple ways to help students thrive in the school environment by introducing them with approaches like Project Based Learning so that they can take ownership of their own learning and be future-ready. Educators should not cling to obsolete methods, rather they should adapt to an innovative mindset in order to shift from traditional learning into more project based learning methodologies.",
    ],
    tags: ["JavaScript", "Coding", "Kids", "Web Development"],
  },
  {
    id: 4,
    title:
      "Transdisciplinary learning in  Coding, Robotics, and IoT STEM Education",
    author: "Funlings Entertainment",
    publicationDate: "January 3, 2023",
    description:
      "Discover the benefits of coding classes for kids and why it's important for their future.",
    imageUrl: "/images/trans.png",
    content: [
      "Transdisciplinary learning is an approach to education that emphasizes the connections and relationships between different fields of study. In the context of coding, robotics, and IoT (Internet of Things) STEM education, transdisciplinary learning can be particularly useful because it helps students see how these different disciplines are interconnected and how they can be applied in real-world situations. Some potential advantages of transdisciplinary learning in coding, robotics, and IoT STEM education include:",
      "Improved problem-solving skills: By learning about multiple disciplines and how they relate to one another, students can develop a more holistic and comprehensive understanding of complex problems. This can help them come up with more creative and effective solutions.",
      "Enhanced critical thinking skills: Transdisciplinary learning encourages students to think critically about how different fields of study intersect and influence one another. This can help students develop the ability to analyze and evaluate information from multiple perspectives.",
      "Greater relevance and engagement: Transdisciplinary learning helps students see the relevance of what they are learning to the real world, which can increase their motivation and engagement in their studies.",
      "Better preparation for the workforce: In today's increasingly interconnected and complex world, employers are seeking employees who are able to think and work across disciplines. Transdisciplinary learning can help students develop the skills and knowledge they need to succeed in these types of careers.",
      "Transdisciplinary learning has the potential to greatly enhance the effectiveness of coding, robotics, and IoT STEM education by helping students develop important skills and understand how these disciplines are interconnected and relevant to the real world.",
      "There are many ways that transdisciplinary learning can be incorporated into coding, robotics, and IoT STEM education. Here are a few examples:",
      "Integrating coding and robotics: By combining coding and robotics education, students can learn how to program and control robotic systems. This can help them understand how these different disciplines are related and how they can be used together to solve real-world problems.",
      "Incorporating real-world projects: By working on projects that involve multiple disciplines, such as coding, robotics, and IoT, students can learn how to apply their knowledge in a more holistic and integrated way. For example, students could work on a project that involves coding a robotic system to collect data from sensors connected to the Internet of Things.",
      "Collaborating with experts from different fields: By working with experts from different fields, such as computer science, electrical engineering, and industrial design, students can learn how to communicate and collaborate across disciplines. This can help them develop important skills that are highly valued in the workforce.",
      "Using interdisciplinary resources: By using resources that cover multiple disciplines, such as textbooks, articles, and online courses, students can learn how different fields are interconnected and how they can be applied in real-world situations.",
    ],
    tags: ["Coding", "Kids", "Education", "Technology", "Future Skills"],
  },
];

const Blog = () => {
  return (
    <section className="bg-[#febb146E] min-h-screen" id="blog">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
          <h2 className="mb-4 text-3xl lg:text-5xl tracking-tight font-extrabold text-gray-900 ">
            {/* Our Blog */}
            <ColorTitles text={"Our Blog"} />
          </h2>
          <p className="font-light text-gray-500 sm:text-xl ">
            Unlock the World of Coding: Empower Young Minds to Create, Innovate,
            and Thrive!
          </p>
        </div>
        <div className="grid gap-8 lg:grid-cols-2">
          {posts.map((post, index) => (
            <motion.article
              variants={fadeIn(
                "right",
                "spring",
                index < 4 ? index * 0.5 : index * 0.3,
                0.75
              )}
              initial="hidden"
              whileInView={"show"}
              className="p-6 bg-white rounded-lg border border-gray-200 shadow-md  "
            >
              <div className="flex justify-between items-center mb-5 text-gray-500">
                <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                  {/* <svg
                    className="mr-1 w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                  </svg>
                  Tutorial*/}
                </span>
                <span className="text-sm">{post.publicationDate}</span>
              </div>
              <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                {post.title}
              </h2>
              <p className="mb-5 font-light text-gray-500 text-ellipsis line-clamp-4">
                {post.content[0]}
                {post.content[1]}
                {post.content[2]}
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-10 h-10 rounded-full"
                    src="/images/MyG.png"
                    alt="Jese Leos avatar"
                  />
                  <span className="font-medium ">{post.author}</span>
                </div>
                <Link
                  to={`/blog/${post.id}`}
                  className="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline"
                >
                  Read more
                  <svg
                    className="ml-2 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </motion.article>
          ))}
          {/* <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md  ">
            <div className="flex justify-between items-center mb-5 text-gray-500">
              <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                <svg
                  className="mr-1 w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                </svg>
                Tutorial
              </span>
              <span className="text-sm">14 days ago</span>
            </div>
            <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
              <a href="#">How to quickly deploy a static website</a>
            </h2>
            <p className="mb-5 font-light text-gray-500 ">
              Static websites are now used to bootstrap lots of websites and are
              becoming the basis for a variety of tools that even influence both
              web designers and developers influence both web designers and
              developers.
            </p>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-4">
                <img
                  className="w-7 h-7 rounded-full"
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                  alt="Jese Leos avatar"
                />
                <span className="font-medium ">Jese Leos</span>
              </div>
              <a
                href="#"
                className="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline"
              >
                Read more
                <svg
                  className="ml-2 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </article>
          <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md ">
            <div className="flex justify-between items-center mb-5 text-gray-500">
              <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                <svg
                  className="mr-1 w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                    clip-rule="evenodd"
                  ></path>
                  <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path>
                </svg>
                Article
              </span>
              <span className="text-sm">14 days ago</span>
            </div>
            <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
              <a href="#">Our first project with React</a>
            </h2>
            <p className="mb-5 font-light text-gray-500 ">
              Static websites are now used to bootstrap lots of websites and are
              becoming the basis for a variety of tools that even influence both
              web designers and developers influence both web designers and
              developers.
            </p>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-4">
                <img
                  className="w-7 h-7 rounded-full"
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                  alt="Bonnie Green avatar"
                />
                <span className="font-medium dark:text-white">
                  Bonnie Green
                </span>
              </div>
              <a
                href="#"
                className="inline-flex items-center font-medium text-primary-600 hover:underline"
              >
                Read more
                <svg
                  className="ml-2 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </article> */}
        </div>
      </div>
    </section>
  );
};

export default Blog;
