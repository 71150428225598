import React from "react";
import ContactForm from "../Models/ContactForm";
import { motion } from "framer-motion";
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { slideIn } from "../utils/motion";

const links = [
  { name: "Email Address", value: "info@funlings-ent.com", icon: EnvelopeIcon },
  { name: "Physical Address​", value: "Nairobi, Kenya", icon: MapPinIcon },
  { name: "Phone Number", value: "+254 734 675032", icon: PhoneIcon },
];
const stats = [
  { name: "Days a week", value: "7" },
  { name: "Full-time tutors", value: "10+" },
];

const Statistics = () => {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-16">
      <img
        src="/images/robo.jpg"
        alt=""
        className="absolute inset-0 opacity-10 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <h2 className="text-3xl text-center font-bold tracking-tight text-white mb-8 lg:text-5xl">
        Contact Us
      </h2>
      <div className="mx-auto grid md:grid-cols-2 gap-4 max-w-7xl px-6 lg:px-8">
        <div>
          <div className="mx-auto max-w-2xl lg:mx-0">
            {/* <p className="mt-6 text-lg leading-8 text-gray-300">
              Join the Learning Revolution. Be a part of our vibrant community
              and embark on an exciting educational adventure that sparks
              curiosity, ignites creativity, and opens doors to limitless
              opportunities. Join us today and empower your child with the
              skills and knowledge they need to thrive in a rapidly evolving
              world.
            </p> */}
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <dl className="mt-16 grid grid-cols-2 gap-8 sm:mt-20  ">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-gray-300">
                    {stat.name}
                  </dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="mx-auto  mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <dl className="mt-16 relative grid grid-cols-2 gap-y-8 sm:mt-20  ">
              {links.map((link) => (
                <div key={link.name} className="flex flex-col">
                  <div className="flex flex-row items-center gap-2">
                    <link.icon
                      className="left-1 top-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <dt className="text-base lg:text-2xl font-bold  leading-7 text-gray-300">
                      {link.name}
                    </dt>
                  </div>
                  <dd className="text-base leading-9 tracking-tight text-white">
                    {link.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <motion.div
          variants={slideIn("right", "tween", 0.2, 1)}
          initial="hidden"
          whileInView={"show"}
        >
          <p className="mt-6 text-lg text-center leading-8 text-gray-300">
            Get in touch with us
          </p>
          <ContactForm />
        </motion.div>
      </div>
    </div>
  );
};

export default Statistics;
