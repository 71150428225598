import React from "react";
import {
  EyeIcon,
  BuildingOffice2Icon,
  RocketLaunchIcon,
} from "@heroicons/react/20/solid";
import { motion } from "framer-motion";
import BackgroundMotions from "../Animations/BackgroundMotions";
import { fadeIn, slideIn } from "../utils/motion";
import ColorTitles from "../utils/colorTitles";

const features = [
  {
    name: "Our Mission.",
    description:
      "To foster creativity and innovation in children, inspiring them to reach their full potential.",
    icon: RocketLaunchIcon,
  },
  {
    name: "Our Vision.",
    description:
      "To empower kids worldwide, igniting their passion and imagination, as they become catalysts for positive change in the world.",
    icon: EyeIcon,
  },
];

const About = () => {
  return (
    <section id="about">
      <div className="overflow-hidden bg-[#ADD8E6] py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <motion.div
                variants={fadeIn("up", "tween", 0.9, 2)}
                initial="hidden"
                whileInView={"show"}
                className="lg:max-w-lg"
              >
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  About Us
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  At
                  <ColorTitles text={" Funlings "} />
                  Entertainment
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  we simplify and add a variety of activities to your children’s
                  education and entertainment calendar all year round. We have
                  adopted a model that allows your children to be part of the
                  real world by making learning a fun experience.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                  <div className="relative pl-9">
                    <div className=" inline font-semibold text-gray-900">
                      <BuildingOffice2Icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      Our Values
                    </div>
                    <ul className="pl-8 list-disc">
                      <li>Passion</li>
                      <li>Dedication</li>
                      <li>Integrity</li>
                      <li>Entrepreneurship</li>
                      <li>Efficiency.</li>
                    </ul>
                  </div>
                </dl>
              </motion.div>
            </div>
            <div className="md:w-[48rem] w-screen items-center max-w-none rounded-xl bg-gray-500/25  shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:mx-auto lg:mx-auto">
              <BackgroundMotions />
              <motion.img
                variants={slideIn("right", "tween", 0.5, 2)}
                initial="hidden"
                animate={"show"}
                src="/images/learn.png"
                alt="Product screenshot"
                className="m-auto"
              />
              <img
                src="/images/logo.png"
                alt="Product screenshot"
                className="m-auto w-2/5"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
