import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVooFW_aQm_-P0yT7JexK0-jTlhqrU364",
  authDomain: "funlings-8b95a.firebaseapp.com",
  databaseURL: "https://funlings-8b95a-default-rtdb.firebaseio.com",
  projectId: "funlings-8b95a",
  storageBucket: "funlings-8b95a.appspot.com",
  messagingSenderId: "594596783392",
  appId: "1:594596783392:web:165e0406d616d022d16345",
  measurementId: "G-69C3DP4N9P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
export const storage = getStorage(app);
