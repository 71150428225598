import React, { useEffect, useState } from "react";
import Carousel from "../Models/Carousel";

const Mover = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setPosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="h-screen absolute bg-gray-900">
      <div className="relative">
        {/* <img
          src="/images/banner.jpg"
          className="h-screen w-screen opacity-10"
          alt="mockup"
        /> */}
        <Carousel />
        <div
          className="w-3 h-3 bg-gray-500 rounded-full absolute"
          style={{ left: position.x, top: position.y }}
        />
      </div>
    </div>
  );
};

export default Mover;
