import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./AboutPage";
import BlogPage from "./BlogPage";
import CoursePage from "./CoursePage";
// import Hackathon from "./Hackathon";
import MagicBit from "./MagicBit";
import SignUpPage from "./SignUpPage";
import Home from "./Home";
import Submit from "./Submit";
import Winners from "./Winners";

const Dashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/submit" element={<Submit />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/blog/:id" element={<BlogPage />} />
      <Route path="/course/:id" element={<CoursePage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/hackathon" element={<Winners />} />
      <Route path="/magicbit" element={<MagicBit />} />
    </Routes>
  );
};

export default Dashboard;
