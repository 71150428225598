import React from "react";
import { useParams } from "react-router-dom";
import { posts } from "../components/Blog";
import NotFound from "./NotFound";

const BlogPage = () => {
  const { id } = useParams();
  const blogPost = posts.find((post) => post.id.toString() === id?.toString());

  if (!blogPost) {
    return <NotFound />; // Render an error message if the blog post is not found
  }

  return (
    <div className="relative isolate overflow-hidden bg-slate-600 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-400 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-500">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-gray-300">
                {blogPost.author}
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-orange-500 sm:text-4xl">
                {blogPost.title}
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-300">
                {blogPost.publicationDate}
              </p>
            </div>
          </div>
        </div>
        <div className="lg:-ml-20 -mt-12 md:py-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="lg:h-[32rem] w-full  object-contain max-w-none rounded-xl  shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={blogPost.imageUrl}
            // src="/images/scratch.png"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 grid gap-y-6 text-gray-200 lg:max-w-lg">
              {blogPost.content.map((post) => (
                <p>{post}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;

// <div>
//   <h2>{blogPost.title}</h2>
//   <p>{blogPost.body}</p>
//   {/* Render the rest of the blog post details */}
// </div>
