import React from "react";

const features = [
  // {
  //   name: "Embark on Your Innovation Journey",
  //   description:
  //     "Unleash your creativity and explore new solutions with our range of robotics and IoT devices. Perfect for students and tech enthusiasts, the Magicbit Pro offers an immersive experience with its cutting-edge features.",
  // },
  {
    name: "All-In-One Solution",
    description:
      "Everything you need comes in a single package, including the Magicbit Core, 10 Pluggable Modules, and Battery Holder.",
  },
  {
    name: "Modular Design",
    description:
      "With our plug-and-play modules, you can easily connect different sensors to the Magicbit platform, eliminating the need for jumper wires and breadboards.",
  },
  {
    name: "Multiple Programming Options",
    description:
      "Develop complex applications effortlessly with hardware, cloud, and mobile integrations, all within the same environment.",
  },
  {
    name: "Magicbot",
    description:
      "Learn and experience the fundamentals of robotics with our Magicbot:",
  },
  // {
  //   name: "Considerations",
  //   description:
  //     "Made from natural materials. Grain and color vary with each item.",
  // },
];

const MagicBit = () => {
  return (
    <div className="bg-white">
      <div className="bg-gray-700 h-20"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Magicbit
          </h2>
          <h2 className="text-xl font-bold tracking-tight mt-4 text-gray-600 sm:text-2xl">
            Embark on Your Innovation Journey
          </h2>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
          <p className="mt-8 font-bold font-serif text-gray-900">
            For inquiries about these devices, please contact us on:
          </p>
          <p className="mt-2 font-serif text-gray-900">
            Phone: +254 734 675032
          </p>
          <p className="mt-2 font-serif text-gray-900">
            Email: info@funlings-ent.com
          </p>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src="/images/magicbit/magic4.png"
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg h-64 bg-gray-100"
          />
          <img
            src="/images/magicbit/magic3.png"
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg h-64 bg-gray-100"
          />
          <img
            src="/images/magicbit/magic2.png"
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg h-64 bg-gray-100"
          />
          <img
            src="/images/magicbit/magic1.png"
            alt="groove."
            className="rounded-lg h-64 bg-gray-100"
          />
        </div>
      </div>
    </div>
  );
};

export default MagicBit;
