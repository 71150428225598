import React, { useRef, useState } from "react";
import { push, ref as dbRef } from "firebase/database";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

const Submit = () => {
  const [zipFile, setZipFile] = useState();

  // const [image, setImage] = useState("");
  // const handleImageUpload = (e) => {
  //   const selectedImage = e.target.files[0]; // Get the selected file
  //   if (selectedImage) {
  //     const imageUrl = URL.createObjectURL(selectedImage); // Create a URL for the selected image
  //     setImage(imageUrl); // Update the images state with the new URL
  //   }
  // };
  const fileInputRef = useRef(null);
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setZipFile(file);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Prevent the default form submission behavior

    //   // Ensure that a file was selected
    //   if (!image) {
    //     return;
    //   }

    const talksRef = dbRef(db, `submissions/`);
    // Add an event listener to the file input element
    //const fileInput = document.getElementById("file_upload");
    // fileInput?.addEventListener("change", async (e) => {
    // Get the selected file

    // Create a reference to the Firebase Storage location where you want to store the file
    // const storageRef = ref(storage, "uploads/" + file.name);
    // // Upload the file to Firebase Storage
    // const uploadTask = storageRef.put(file);

    // // Monitor the upload progress
    // uploadTask.on(
    //   "state_changed",
    //   (snapshot) => {
    //     // Handle progress updates here (optional)
    //   },
    //   (error) => {
    //     // Handle errors here
    //     console.error("Error uploading file: ", error);
    //   },
    //   () => {
    //     // Upload successful, you can do further processing here if needed
    //     console.log("File uploaded successfully");
    //   }
    // );
    const data = {
      name: `${
        event.target.kids_first_name.value +
        " " +
        event.target.kids_last_name.value
      }`,
      age_group: `${event.target.age.value}`,
      email: `${event.target.kids_email.value}`,
      project_name: `${event.target.project_name.value}`,
      project_link: `${event.target.project_link.value}`,
      platform: `${event.target.platform.value}`,
      language: `${event.target.language.value}`,
      project_description: `${event.target.about.value}`,
    };
    if (!zipFile) {
      push(talksRef, {
        name: `${
          event.target.kids_first_name.value +
          " " +
          event.target.kids_last_name.value
        }`,
        age_group: `${event.target.age.value}`,
        email: `${event.target.kids_email.value}`,
        project_name: `${event.target.project_name.value}`,
        project_link: `${event.target.project_link.value}`,
        platform: `${event.target.platform.value}`,
        language: `${event.target.language.value}`,
        project_description: `${event.target.about.value}`,
      });
      emailjs
        .sendForm(
          "service_dtkd2sl",
          "template_qc4hevr",
          event.target,
          "W33G4MF3rz8Ddh06o"
        )
        .then(
          (result) => {
            console.log(result.text);
            Swal.fire({
              title: "Success!",
              text: "Your Submission was successful!",
              icon: "success",
              timer: 2000,
            });
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      try {
        const storageRef = ref(storage, `uploads/${zipFile?.name}`);
        const uploadTask = uploadBytesResumable(storageRef, zipFile);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const progress =
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // alert("Upload is " + progress + "% done");

            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                Swal.fire({
                  title: "Please Wait ...",
                  html: "Upload is " + progress + "% done",
                  // timer: remaining,
                  // timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
                break;
              default:
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              push(talksRef, {
                ...data,
                downloadURL,
              });
            });
            emailjs
              .sendForm(
                "service_dtkd2sl",
                "template_qc4hevr",
                event.target,
                "W33G4MF3rz8Ddh06o"
              )
              .then(
                (result) => {
                  console.log(result.text);
                  Swal.fire({
                    title: "Success!",
                    text: "Your Submission was successful!",
                    icon: "success",
                    timer: 2000,
                  });
                },
                (error) => {
                  console.log(error.text);
                }
              );
          }
        );
      } catch (error) {
        Swal.fire({
          //position: "top-end",
          icon: "error",
          title: `${error}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }

    //});

    //   // Generate a unique filename (you can use your logic to create one)
    //   const uniqueFileName = `${Date.now()}_${image?.name}`;
    //   const storageRef = ref(storage, `zipFiles/${uniqueFileName}`);

    // try {
    //   // Upload the zip image to Firebase Storage
    //   await uploadBytes(storageRef, image);

    //   // Get the download URL of the uploaded zip file
    //   const downloadURL = await getDownloadURL(storageRef);

    //   // Log the download URL (you can use it as needed)
    //   console.log("Download URL:", downloadURL);
    // } catch (error) {
    //   console.error("Error uploading zip file:", error);
    // }
    // // push(talksRef, {
    // //   name: `${
    // //     event.target.kids_first_name.value +
    // //     " " +
    // //     event.target.kids_last_name.value
    // //   }`,
    // //   age_group: `${event.target.age.value}`,
    // //   email: `${event.target.kids_email.value}`,
    // //   project_name: `${event.target.project_name.value}`,
    // //   project_link: `${event.target.project_link.value}`,
    // //   platform: `${event.target.platform.value}`,
    // //   language: `${event.target.language.value}`,
    // //   project_description: `${event.target.about.value}`,
    // // });
    // // console.log(event.target);

    // Reset the form after submission if desired
    event.target.reset();
  };
  return (
    <section className="text-gray-600 body-font">
      <div className="bg-gray-700 h-20"></div>
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        {/* <div className="lg:flex-grow md:w-full lg:pr-8 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"> */}
        <div className=" bg-gray-100 mr-4 rounded-lg md:p-8 p-2 flex flex-col-reverse md:ml-auto w-full mt-10 md:mt-0 drop-shadow-xl">
          <form onSubmit={handleSubmit}>
            <img src="/images/logo.png" className="mx-auto  h-20" alt="" />
            <h2 className="text-center font-bold mb-4">Kids Information</h2>
            <div className="container mx-auto mb-8 w-5/6 md:w-1/2 border border-red-600 bg-red-600/20 p-2 rounded-md">
              <h2 className="text-center font-bold underline">Note</h2>
              <p className="text-sm text-center text-gray-600">
                <em>All fields are mandatory </em> <strong>EXCEPT </strong>
                <em>upload files field.</em> If a field does not apply or you
                have no response, please use "N/A" to indicate that. Thank you!
              </p>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="kids_first_name"
                  id="kids_first_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="kids_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  First name
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="kids_last_name"
                  id="kids_last_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="kids_last_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Last name
                </label>
              </div>
            </div>

            <div className="grid place-items-end md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <label
                  htmlFor="age"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Age Group
                </label>
                <div className="">
                  {/* <input
                    type="text"
                    name="grade"
                    id="grade"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  /> */}

                  <select
                    id="age"
                    name="age"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>7 - 10 Years</option>
                    <option>11 - 14 Years</option>
                  </select>
                </div>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="kids_email"
                  id="kids_email"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="kids_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email
                </label>
              </div>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <div className="mt-2">
                <input
                  type="text"
                  name="project_name"
                  id="project_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="project_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Project name
                </label>
              </div>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <div className="mt-2">
                <input
                  type="text"
                  name="project_link"
                  id="project_link"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="project_link"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Project link
                </label>
              </div>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <div className="mt-2">
                <input
                  type="text"
                  name="platform"
                  id="platform"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="platform"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Development Platform (Scratch, Thunkable, Repl, VS Code )
                </label>
              </div>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <div className="mt-2">
                <input
                  type="text"
                  name="language"
                  id="language"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="language"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Programming Language Used
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Project Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Upload Files (Only if applicable)*
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    {/* <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="container mx-auto"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 9.438V8.5h1v.938a1 1 0 0 0 .03.243l.4 1.598-.93.62-.93-.62.4-1.598a1 1 0 0 0 .03-.243z" />
                      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-4-.5V2h-1V1H6v1h1v1H6v1h1v1H6v1h1v1H5.5V6h-1V5h1V4h-1V3h1zm0 4.5h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.109 0l-.93-.62a1 1 0 0 1-.415-1.074l.4-1.599V8.5a1 1 0 0 1 1-1z" />
                    </svg>
                    <div
                      className="mt-4 flex text-sm leading-6 text-gray-600"
                      //onClick={handleFileUpload}
                    >
                      {zipFile ? (
                        <div className="relative z-0 w-full mb-6 group">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white px-4 font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Change File</span>
                            <input
                              id="file-upload"
                              name="file_upload"
                              type="file"
                              className="sr-only"
                              ref={fileInputRef}
                              accept=".zip"
                              onChange={handleFileSelect}
                            />
                          </label>
                          <p className="pl-1">{zipFile?.name}</p>
                        </div>
                      ) : (
                        <div className="relative z-0 w-full mb-6 group">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white px-4 font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Upload Zip file</span>
                            <input
                              id="file-upload"
                              name="file_upload"
                              type="file"
                              className="sr-only"
                              ref={fileInputRef}
                              accept=".zip"
                              onChange={handleFileSelect}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                      )}
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      ZIP, RAR, TAR up to 20MB
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled
              className="text-white mt-4 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Submissions Closed
            </button>
          </form>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="/images/IOT.jpg"
          />
        </div>
      </div>
    </section>
  );
};

export default Submit;
