import React from "react";
import ColorTitles from "../utils/colorTitles";

const Winners = () => {
  return (
    <div>
      <div className="bg-gray-700 h-20"></div>
      <div>
        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="topcoders"
                src="/images/winners/topcoders.png"
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-2xl text-3xl mb-4 font-medium text-gray-900">
                🌟 Celebrating Young Coders' Brilliance at the
                <br className="hidden lg:inline-block" />
                Funlings Virtual Kids Coding Hackathon! 🌟
              </h1>
              <p className="mb-2 leading-relaxed">
                On Saturday, November 4th, 2023, the Visa Innovation Studio in
                Nairobi was filled with excitement and innovation as we
                celebrated the finals of the Funlings Virtual Kids Coding
                Hackathon, themed "Coding for a Brighter Future." It was a
                fantastic opportunity to acknowledge and applaud the incredible
                talent and dedication of our young coders and tech enthusiasts
                while recognizing the winners who left us in awe.
              </p>
              <p className="mt-2 leading-relaxed">
                We extend our heartfelt gratitude to every participant who
                joined us for this remarkable hackathon. Your dedication,
                creativity, and unwavering perseverance were truly impressive.
                The young coders and tech enthusiasts showcased the
                transformative power of coding, not only in creating games and
                applications but also in driving innovation, solving problems,
                and shaping a brighter future.
              </p>
              {/* <div className="flex justify-center">
                <button className="inline-flex text-white bg-pink-500 border-0 py-2 px-6 focus:outline-none hover:bg-pink-600 rounded text-lg">
                  Button
                </button>
                <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
                  Button
                </button>
              </div> */}
            </div>
          </div>
        </section>
        <section className="text-gray-600 bg-[#942a4f2e] body-font">
          <div className="container px-5 py-16 mx-auto">
            <h1 className="text-2xl mb-8 text-center font-bold tracking-tight text-[#942a4f] sm:text-5xl">
              <ColorTitles text={"Our Winners"} />
              {/* Funlings Entertainment */}
            </h1>

            <div className="flex flex-wrap -m-4">
              <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                <div className="h-full text-center">
                  <img
                    alt="testimonial"
                    className="w-72 h-72 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                    src="/images/winners/Liam.jpg"
                  />
                  <h1 className="text-2xl mb-8 text-center font-bold tracking-tight text-[#DAA520] sm:text-5xl">
                    1{/* Funlings Entertainment */}
                  </h1>

                  <h2 className="text-gray-900 font-medium title-font tracking-wider uppercase text-sm">
                    Liam Shah
                  </h2>
                  <p className="text-gray-500">Kenton College Preparatory</p>
                  <span className="inline-block h-1 w-10 rounded bg-pink-500 mt-6 mb-4"></span>
                  <p className="leading-relaxed">
                    The first-place winner, Liam Shah from Kenton College
                    Preparatory, wowed us with "drEYEving." His project
                    addressed myopia stemming from increased screen time and
                    gadget use by developing a game to strengthen eye muscles
                    and coordination.
                  </p>
                </div>
              </div>
              <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                <div className="h-full text-center">
                  <img
                    alt="testimonial"
                    className="w-72 h-72 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                    src="/images/winners/Neil.jpg"
                  />
                  <h1 className="text-2xl mb-8 text-center font-bold tracking-tight text-[#C0C0C0] sm:text-5xl">
                    2{/* Funlings Entertainment */}
                  </h1>
                  <h2 className="text-gray-900 font-medium title-font tracking-wider uppercase text-sm">
                    Neil Juma
                  </h2>
                  <p className="text-gray-500">Serve Academy Sabatia</p>
                  <span className="inline-block h-1 w-10 rounded bg-pink-500 mt-6 mb-4"></span>
                  <p className="leading-relaxed">
                    Neil Juma from Serve Academy Sabatia secured the second spot
                    with "Letter Clicker/Space Speller," a remarkable creation
                    designed to help children with spelling difficulties improve
                    their skills. Well done!
                  </p>
                </div>
              </div>
              <div className="lg:w-1/3 lg:mb-0 p-4">
                <div className="h-full text-center">
                  <img
                    alt="testimonial"
                    className="w-72 h-72 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                    src="/images/winners/Baraka.jpg"
                  />
                  <h1 className="text-2xl mb-8 text-center font-bold tracking-tight text-[#CD7F32] sm:text-5xl">
                    3{/* Funlings Entertainment */}
                  </h1>
                  <h2 className="text-gray-900 font-medium title-font tracking-wider uppercase text-sm">
                    Mshaha Baraka
                  </h2>
                  <p className="text-gray-500">Brookside Prep School</p>
                  <span className="inline-block h-1 w-10 rounded bg-pink-500 mt-6 mb-4"></span>
                  <p className="leading-relaxed">
                    In third place, Mshaha Baraka from Brookside Prep School who
                    amazed us with "S.U.L Living Solutions," an e-commerce
                    website promoting sustainable urban solutions to combat
                    global warming in urban areas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-20">
              <h1 className="text-2xl mb-8 text-center font-bold tracking-tight text-[#942a4f] sm:text-5xl">
                <ColorTitles text={"Participants"} />
              </h1>
              <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                The hackathon saw an impressive turnout of 73 registrations from
                various schools across Kenya, with 13 finalists hailing from:
              </p>
            </div>
            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">
                    Brookside Prep School
                  </span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">
                    Damacrest Schools
                  </span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">
                    Njeru Junior Academy
                  </span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">
                    Juja St. Peters
                  </span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">
                    Juja Preparatory School
                  </span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">
                    Kenton College Preparatory
                  </span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">
                    Serve Academy Sabatia
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-gray-600 bg-purple-500/20 body-font">
          <div className="container px-5 py-24 mx-auto ">
            <h1 className="text-2xl mb-8 text-center font-bold tracking-tight text-[#942a4f] sm:text-5xl">
              <ColorTitles text={"Vote of Thanks"} />
            </h1>
            <div className="lg:w-2/3 mx-auto">
              <img
                alt="gallery"
                className="w-full rounded-md object-cover h-74 object-center block   inset-0"
                src="/images/winners/showcasing.png"
              />
            </div>
            <section className="text-gray-600 body-font">
              <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-wrap -m-4">
                  <div className="p-4 md:w-1/2 w-full">
                    <div className="h-full bg-gray-100 p-8 rounded">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="block w-5 h-5 text-gray-400 mb-4"
                        viewBox="0 0 975.036 975.036"
                      >
                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                      </svg>
                      <p className="leading-relaxed mb-6">
                        To all our winners, your dedication and talent are a
                        true inspiration. But let's not forget that every
                        participant here possesses the potential to change the
                        world through code. Keep dreaming big, continue
                        learning, and code for a brighter future.
                      </p>
                      {/* <a className="inline-flex items-center">
                        <img
                          alt="testimonial"
                          src="https://dummyimage.com/106x106"
                          className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                        />
                        <span className="flex-grow flex flex-col pl-4">
                          <span className="title-font font-medium text-gray-900">
                            Holden Caulfield
                          </span>
                          <span className="text-gray-500 text-sm">
                            UI DEVELOPER
                          </span>
                        </span>
                      </a> */}
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 w-full">
                    <div className="h-full bg-gray-100 p-8 rounded">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="block w-5 h-5 text-gray-400 mb-4"
                        viewBox="0 0 975.036 975.036"
                      >
                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                      </svg>
                      <p className="leading-relaxed mb-6">
                        Funlings extends heartfelt thanks to our judges,
                        mentors, volunteers, VISA and everyone who contributed
                        to the success of this event. Your dedication and
                        support were invaluable in making this event a
                        resounding success.
                      </p>
                      {/* <a className="inline-flex items-center">
                        <img
                          alt="testimonial"
                          src="https://dummyimage.com/107x107"
                          className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                        />
                        <span className="flex-grow flex flex-col pl-4">
                          <span className="title-font font-medium text-gray-900">
                            Alper Kamu
                          </span>
                          <span className="text-gray-500 text-sm">
                            DESIGNER
                          </span>
                        </span>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Winners;
