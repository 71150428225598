import React from "react";
import { motion } from "framer-motion";
import ColorTitles from "../utils/colorTitles";
import { fadeIn } from "../utils/motion";
import { Link } from "react-router-dom";

export const courses = [
  {
    id: 1,
    name: "Scratch Programming",
    description:
      "Scratch is an object-oriented coding course for young kids, designed to improve problem-solving skills and creative thinking.",
    highlights: [
      "Scratch, a programming language that was developed by MIT, rises to the challenge. It uses colorful animation to help illustrate difficult concepts and opens a door to the wonderful world of code.",
      "This introductory course invites kids to create games, videos and stories, while learning the foundations of coding so there’s no need for previous experience. Kids will get to know the Scratch learning environment, its friendly animated characters and cool graphic design tools. They will create fun games by dragging and dropping built-in blocks of code, which will help them implement the internal logic and structure of programming.",
    ],
    imageUrl: "/images/scratch.png",
  },
  {
    id: 2,
    name: "Advanced Scratch Programming",
    description:
      "Welcome to our Advanced Scratch Programming course for kids, where young coding enthusiasts can take their skills to the next level and unlock the full potential of Scratch, a visual programming language.",
    highlights: [
      "In this course, we dive deeper into Scratch, exploring advanced concepts and techniques that will challenge and inspire young minds. Designed for students who have a solid understanding of Scratch basics, this course aims to expand their programming capabilities and empower them to create more complex and impressive projects.",
    ],
    imageUrl: "/images/scratch-3.png",
  },

  {
    id: 3,
    name: "Python for kids",
    description:
      "Children with some existing programming experience can learn to write code in one of the most common languages in the industry as well as gaining valuable skills for the future",
    highlights: [
      "Python is a programming language with many advantages: it is one of the most common languages used in the industry, it is taught in most universities in various faculties – not only programming, and unlike other programming languages it can be used for almost any purpose. ",
      "The course is intended for children with some previous programming experience. Your child must be familiar with basic concepts such as variables, conditions and strings so that we can teach them to write code in the programming language of adults. During the course, they will work on projects and develop games in the Python language. In the first half of the course, the children create simple games in order to assimilate concepts and foundations in the new language.",
    ],
    imageUrl: "/images/Python.jpg",
  },
  {
    id: 4,
    name: "Javascript for kids",
    description:
      "Kids will learn the foundations of JavaScript, one of the most popular coding languages. During this course they will acquire problem-solving and thought-development skills.",
    highlights: [
      "JavaScript is the most widely used coding language worldwide. Through its simplicity, it allows you to create websites that are both beautiful to look at and easy to use. This knowledge will convey the building blocks that every programmer should be familiar with.",
      "The learning process becomes very effective when children learn a new skill (such as coding) and apply it to websites and games that they conceptualize and design. It also brings a sense of fulfilment as they traverse and overcome bugs and issues in their coding process. Sweating over a project and seeing it come to life is empowering to children.",
    ],
    imageUrl: "/images/scripting.png",
  },
  {
    id: 5,
    name: "Web Development",
    description:
      "Welcome to our Web Development for Kids course, where young minds can unleash their creativity and embark on an exciting journey into the world of coding and website creation. ",
    highlights: [
      "In this course, we introduce children to the fundamentals of web development, providing them with the necessary knowledge and skills to design and build their own interactive websites. Through engaging and hands-on activities, kids will learn how to craft visually appealing web pages, incorporate dynamic elements, and bring their ideas to life on the internet.",
    ],
    imageUrl: "/images/webdev.png",
  },
  {
    id: 6,
    name: "Mobile App Development",
    description:
      "Kids will develop Apps and games for smartphones, start coding immediately, and make their first steps as digital creators.",
    highlights: [
      "Coding is all about thinking. Learning to code combines scientific knowledge with creativity and learning a whole new language. It requires high activity of multiple areas in the brain and altogether empowers children. When children code they learn how to express themselves, develop procedural thinking, and strengthen their confidence, they acquire a new set of skills that help them shift from just consuming information to becoming digital creators.",
      "In the App development course, students will learn the basics of coding Apps and games for smartphones. They will write code for the first time, develop and design their own unique self-made products.",
    ],
    imageUrl: "/images/App development.png",
  },

  {
    id: 7,
    name: "Robotics and IoT",
    description:
      "In this course, you will learn about the technical knowledge of robotics, fundamentals of programming (coding), statements, variables, working with sensors, and much more. The program is designed to inculcate deep technical skills along with leadership, innovation, and entrepreneurial thinking.",
    highlights: [
      "Basics of Electronics components and sensors",
      "Basic of Electrical circuits",
      "Basics of Microcontroller",
      "Programming(Coding), Python",
      "Design Thinking and Prototyping Products",
    ],
    imageUrl: "/images/IOT.jpg",
  },
  {
    id: 8,
    name: "Data Science for kids",
    description:
      "Welcome to Data Science for kids, a captivating and hands-on course designed to introduce kids to the exciting world of data science! In this interactive program, young learners will embark on an engaging journey, discovering the power of data and gaining valuable skills in data analysis, visualization, and problem-solving.",
    highlights: [
      "Data Science for kids is tailored specifically for children aged 10-18, providing a supportive and stimulating environment where they can develop a solid foundation in data science concepts. Through fun activities, real-life examples, and practical projects, students will learn how to extract meaningful insights from data and understand its significance in various fields.",
      "Key Course Features:",
      "Introduction to Data Science: Kids will be introduced to the fundamentals of data science, exploring its applications, the data life cycle, and the role of data scientists in solving real-world problems.",
      "Data Collection and Cleaning: Students will learn how to gather data from different sources, clean and organize it, and prepare it for analysis, ensuring data quality and accuracy.",
      "Exploratory Data Analysis: This module will focus on techniques to explore and analyze data, including statistical measures, data visualization, and identifying patterns and trends.",
      "Introduction to Programming: Kids will be introduced to the basics of programming using a kid-friendly programming language, allowing them to manipulate data and perform computations.",
      "Data Visualization: Students will learn how to present data effectively using graphs, charts, and other visual representations, enabling them to communicate their findings clearly.",
      "Machine Learning: An introduction to the concepts of machine learning will be provided, where students will explore supervised and unsupervised learning algorithms and understand their applications.",
      "Real-World Applications: Kids will delve into various domains where data science is applied, such as healthcare, sports, finance, and social media, understanding how data-driven insights impact decision-making.",
      "Ethical Data Use: Students will learn about the importance of ethical considerations in data science, including data privacy, bias, and responsible data handling.",
    ],
    imageUrl: "/images/datascience.png",
  },

  {
    id: 9,
    name: "Chess for kids",
    description:
      "Welcome to Chess for kids, an exciting and educational course designed to introduce kids to the captivating game of chess! This interactive program aims to nurture young minds by teaching them the fundamentals of chess while promoting critical thinking, strategic planning, and problem-solving skills.",
    highlights: [
      "Chess for kids is tailored specifically for children aged 7-17, providing a stimulating environment where they can develop a deep understanding of the game. Through engaging lessons, fun activities, and friendly competitions, young learners will embark on a journey of discovery, exploring the intricacies of chess and honing their mental acuity.",
      "Key Course Features:",
      "Introduction to Chess: Kids will be introduced to the game's history, rules, and basic gameplay mechanics, laying a solid foundation for their chess journey.",
      "Chess Pieces and Movements: Students will learn about the unique characteristics and movements of each chess piece, including pawns, rooks, knights, bishops, the queen, and the king.",
      "Tactical Skills: This module will focus on tactical maneuvers, such as forks, pins, skewers, and discovered attacks, enhancing children's ability to think strategically and plan ahead.",
      "Opening Strategies: Kids will explore various opening strategies and principles, understanding the importance of controlling the center of the board and developing their pieces effectively.",
      "Endgame Mastery: Students will delve into the intricacies of endgame scenarios, learning valuable techniques, such as checkmating with different piece combinations and achieving a winning advantage.",
      "Problem Solving: Engaging puzzles and problem-solving exercises will challenge kids to apply their chess knowledge and develop their analytical thinking skills.",
      "Friendly Tournaments: Periodic tournaments will be organized within the course, allowing students to put their skills to the test and foster a competitive yet supportive spirit among participants.",
      "Chess Etiquette and Sportsmanship: Children will be encouraged to embrace fair play, learn good sportsmanship, and respect their opponents, fostering a positive and respectful chess community.",
    ],
    imageUrl: "/images/chess.jpg",
  },
];

const Courses = () => {
  return (
    <section className="bg-[#E6E6FA]" id="courses">
      <div className="py-8 px-2 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
        <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 className="mb-4 text-3xl lg:text-5xl tracking-tight font-extrabold text-gray-900 ">
            <ColorTitles text={"Our Courses"} />
          </h2>
          <p className="font-light text-gray-600 lg:mb-16 sm:text-xl">
            Discover, Learn, Excel. Unlock a world of knowledge and skills with
            our diverse range of courses designed to inspire young minds. From
            coding and robotics to space science and chess, embark on an
            enriching learning journey that sparks curiosity, fosters
            creativity, and empowers your child for future success.
          </p>
        </div>
        <div className="grid gap-4 mb-6 lg:mb-16 md:grid-cols-3">
          {courses.map((course, index) => (
            <motion.div
              variants={fadeIn(
                "right",
                "spring",
                index < 4 ? index * 0.5 : index * 0.3,
                0.75
              )}
              initial="hidden"
              whileInView={"show"}
              key={course.id}
              className=" bg-gray-50 rounded-lg shadow overflow-hidden"
            >
              <div>
                <img
                  className="w-full md:h-56 rounded-lg sm:rounded-none sm:rounded-l-lg"
                  src={course.imageUrl}
                  alt="Bonnie Avatar"
                />
              </div>

              <div className="p-5">
                <h3 className="text-xl font-bold tracking-tight text-gray-900 ">
                  {course.name}
                </h3>

                <p className="mt-3 mb-4 text-ellipsis line-clamp-3 font-light text-gray-500 ">
                  {course.description}
                </p>
                <div className="w-full mx-auto">
                  <Link
                    to={`/course/${course.id}`}
                    className="rounded-md bg-orange-800 px-3.5 py-2.5 text-sm font-semibold text-gray-200 hover:text-orange-800 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Enroll Now
                  </Link>
                </div>
                {/* <span className="text-gray-500 ">
                  {course.skills.map((skill) => (
                    <ul className="list-disc ml-8 grid grid-cols-3">
                      <li>{skill}</li>
                    </ul>
                  ))}
                </span> */}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Courses;
