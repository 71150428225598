import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import Dashboard from "./Paths";

// const router = createBrowserRouter([{ path: "*", Component: Root }]);

// export default function App() {
//   return <RouterProvider router={router} />;
// }

export default function App() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/*" element={<Dashboard />} />
        {/* <Route path="*" element={<Navigate to="/about" replace />} /> */}
        {/* <Route path="/about" element={<AboutPage />} />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route path="/course/:id" element={<CoursePage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/hackathon" element={<Hackathon />} />
        <Route path="/magicbit" element={<MagicBit />} /> */}
      </Routes>
      <Footer />
    </>
  );
}
