import React from "react";

// Define the pool of colors
const colorPool = [
  "text-red-500",
  "text-blue-500",
  "text-green-500",
  "text-purple-500",
  "text-yellow-500",
  "text-pink-500",
  "text-indigo-500",
];

// Function to assign colors to letters
const assignColorsToLetters = (text) => {
  return Array.from(text).map((letter, index) => (
    <span key={index} className={colorPool[index % colorPool.length]}>
      {letter}
    </span>
  ));
};

const ColorTitles = ({ text }) => {
  const coloredText = assignColorsToLetters(text);

  return <span>{coloredText}</span>;
};

export default ColorTitles;
