import React, { useEffect, useState } from "react";
import ColorTitles from "../utils/colorTitles";

export const quoteChoices = [
  {
    name: "Sarah",
    value:
      "My kids absolutely love Funlings! The coding activities are not only fun but also educational. It's amazing to see my children learn the fundamentals of programming while playing with their favorite characters. Funlings has made coding enjoyable and accessible for my little ones!",
  },
  {
    name: "Emily",
    value:
      "Funlings has been a lifesaver during the lockdown! My kids have been spending more time indoors, and Funlings has kept them entertained and intellectually stimulated. It's not just screen time; it's meaningful screen time where they learn problem-solving and critical thinking skills. Thank you, Funlings, for providing a safe and educational engagement for my children!",
  },
  {
    name: "The Ramirez family",
    value:
      "Funlings has turned coding into a family bonding activity for us. It's amazing how it appeals to both kids and parents alike. We spend quality time together, collaborating on coding challenges and cheering each other on. Funlings has made learning fun for our entire household!",
  },
  {
    name: "Karen",
    value:
      "As a tech-savvy kid, my son had a head start in coding, but Funlings has taken his interest to a whole new level. The advanced coding activities and puzzles keep him engaged for hours. He's become so confident in coding that he's now helping his friends at school learn too. I couldn't be prouder of his growth, all thanks to Funlings!",
  },
  {
    name: "Mr. Thompson",
    value:
      "Funlings is an excellent tool for nurturing creativity and logical thinking in kids. My son's imagination has flourished, and he's constantly coming up with new ways to code and interact with the characters. Funlings is a gateway to a world of endless possibilities!",
  },
  {
    name: "Grandma Linda",
    value:
      "Funlings is the best gift I could have given to my grandkids! They're having a blast while learning something valuable. It's incredible to see them explore the world of coding with enthusiasm and curiosity. Funlings is an investment in their future, and I'm thrilled to be a part of it!",
  },
  {
    name: "Amy",
    value:
      "Funlings is brilliant for introducing coding to kids in a playful manner. The way it combines fun and learning is truly commendable. My daughter has developed problem-solving skills and persistence through the challenges, and she's genuinely excited about coding now. Thank you, Funlings, for making coding accessible and enjoyable for children!",
  },
  {
    name: "Sammy",
    value:
      "I am glad Funlings made it possible to offer the coding classes despite the time difference. Funlings not only teaches them coding skills but also fosters a love for technology and innovation. I can't wait to see what my little tech enthusiasts will create in the future!",
  },
];
function getRandomQuote() {
  const randomIndex = Math.floor(Math.random() * quoteChoices.length);
  return quoteChoices[randomIndex];
}
// const randomIndex = Math.floor(Math.random() * quoteChoices.length);

const Testimonials = () => {
  const [quote, setQuote] = useState({ name: "", value: "" });

  useEffect(() => {
    setQuote(getRandomQuote());
  }, []);

  return (
    <article className="bg-[#972a502e] overflow-hidden flex flex-col">
      <h3 className="mt-4 text-3xl inline-block py-4 font-extrabold text-center text-black sm:text-4xl lg:text-5xl">
        <ColorTitles text={"Testimonials"} />
      </h3>
      <section className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
        <svg
          className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-secondary"
                fill="#972a50"
              />
            </pattern>
          </defs>
          <rect
            width={784}
            height={404}
            fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
          />
        </svg>

        <svg
          className="hidden lg:block absolute right-full  transform translate-x-1/2 -translate-y-1/2"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="56409614-3d62-4985-9a10-7ca758a8f4f0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-secondary"
                fill="#972a50"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
          />
        </svg>

        <section className="relative lg:flex lg:items-center">
          <section className="hidden lg:block lg:flex-shrink-0">
            <img
              className="h-32 w-48 rounded-md xl:h-36 xl:w-64"
              src="/images/logo.png"
              alt=""
            />
          </section>

          <section className="relative lg:ml-10">
            <svg
              className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-white opacity-50"
              stroke="#972a50"
              fill="none"
              viewBox="0 0 144 144"
              aria-hidden="true"
            >
              <path
                strokeWidth={2}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <section className="text-2xl leading-9 font-medium text-black">
                <p>{quote.value}</p>
              </section>
              <section className="mt-8">
                <section className="flex">
                  <section className="flex-shrink-0 lg:hidden">
                    {/* <img
                        className="h-9 w-9 rounded-full"
                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                  </section>
                  <section className="ml-4 lg:ml-0">
                    <p className="text-base font-medium text-black">
                      {quote.name}
                    </p>
                  </section>
                </section>
              </section>
            </blockquote>
          </section>
        </section>
      </section>
    </article>
  );
};

export default Testimonials;
