import React from "react";
import About from "../components/About";
import Blog from "../components/Blog";
import Courses from "../components/Courses";
import Hero from "../components/Hero";
import Partners from "../components/Partners";
import Statistics from "../components/Statistics";
import FAQS from "../components/FAQS";
import Team2 from "../components/Team2";
import Testimonials2 from "../components/Testimonials2";

const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <Courses />
      <Blog />
      <Partners />
      <Testimonials2 />
      <Statistics />
      <Team2 />
      <FAQS />
    </div>
  );
};

export default Home;
